<template>
  <div>404</div>
</template>

<script>
export default {
  name: "PageNotFound",
  /*beforeCreate() {
    window.location.href = '/404.html'
  }*/
}
</script>

<style scoped>

</style>